export function formatDate(date) {
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    return year + "-" + month + "-" + day;
}

export function avg(value1, value2) {
    if (value1 != undefined && value2 != undefined) {
        return value1 / value2
    }
    return value1
}

export function number(value) {
    if (value != undefined) {
        return new Intl.NumberFormat('zh-CN').format(value)
    }
    return ''
}

export function pct(value1, value2) {
    if (value1 != undefined && value2 != undefined) {
        return (value1 / value2 * 100).toFixed(2) + '%'
    }
    return ''
}

export function perMille(value1, value2) {
    if (value1 != undefined && value2 != undefined) {
        return (value1 / value2 * 1000).toFixed(2) + '‰'
    }
    return ''
}

export function roi(money, refund, cost) {
    if (money != undefined && cost != undefined) {
        if (refund != undefined) {
            money -= refund;
        }
        return pct(money, cost);
    }
    return ''
}

export function currency(value) {
    if (value != undefined) {
        return new Intl.NumberFormat('zh-CN', { style: 'currency', currency: 'CNY' }).format(value)
    }
    return ''
}

export function profit(money, refund, cost) {
    if (money != undefined && cost != undefined) {
        if (refund != undefined) {
            money -= refund;
        }
        return money - cost
    } else if (money != undefined) {
        if (refund != undefined) {
            money -= refund;
        }
        return money
    } else if (cost != undefined) {
        return -cost
    }
}

export function profit2(money, refund, cost, return_cost) {
    if (money != undefined) {
        if (refund != undefined) {
            money -= refund;
        }
        if (cost != undefined) {
            if (return_cost != undefined) {
                cost -= return_cost;
            }
            money -= cost;
        }
        return money
    }
}

export function minus(money1, money2) {
    if (money1 != undefined) {
        if (money2 != undefined) {
            money1 -= money2;
        }
        return money1
    } else if (money2 != undefined) {
        return -money2
    }
}

export function pushCount(value1, value2) {
    if (value1 != undefined && value2 != undefined) {
        return number(value1) + '/' + number(value2)
    } else if (value1 != undefined) {
        return number(value1) + '/0'
    } else if (value2 != undefined) {
        return '0/' + number(value2)
    }
    return ''
}

export function discount_rate(value1, value2) {
    if (value1 != undefined && value2 != undefined) {
        return (value1 / value2 * 100 - 100).toFixed(2) + '%'
    }
    return ''
}

export function conversionType(val) {
    let map = {
        1: '激活',
        2: '注册',
        3: '订单',
        4: '付费',
        5: '退款',
        6: '点击转换（非会员）'
    }
    return map[val]
}


export function accountStatus(val) {
    let map = {
        0: '未启用',
        1: '正常',
        2: '配置失效'
    }
    return map[val]
}
