<template>
    <form class="form" method="post" @submit.prevent="handleSubmit">
        <div class="form-item">
            <label>账户名:</label>
            <Selection placeholder="请选择归属账户" v-model="data.account_id" :readonly="true" remote="accounts">
            </Selection>
            <label>数据源:</label>
            <Selection placeholder="请选择数据源" v-model="data.database_id" remote="databases">
            </Selection>
        </div>
        <div class="form-item">
            <label>应用名称:</label>
            <input type="text" class="form-control" v-model.trim="data.name">
            <label>应用类型:</label>
            <Selection :items="{ 'App': 'App', 'Web': 'Web' }" placeholder="请选择类型" v-model="data.type"></Selection>
        </div>
        <div class="form-item" v-if="data.type">
            <label v-if="data.type == 'App'">渠道标识:</label>
            <label v-else-if="data.type == 'Web'">后缀标识:</label>
            <input type="text" class="form-control" v-model.trim="data.param1">
            <label>扩展参数:</label>
            <input type="text" class="form-control" v-model.trim="data.param2">
        </div>
        <div class="form-item">
            <label>oCPC Token:</label>
            <textarea v-model.trim="data.ocpc_token" class="form-control colspan-2"></textarea>
        </div>
        <div class="form-action">
            <button type="submit" class="btn btn-primary">保 存</button>
            <button type="button" class="btn btn-light" @click="$router.back()">取 消</button>
        </div>
    </form>
</template>

<script>
import Selection from './Selection.vue'

export default {
    name: 'AccountForm',
    components: {
        Selection
    },
    props: {
        value: Object
    },
    data() {
        return {
            data: this.value,
            accounts: [],
            types: {
                1: '激活',
                2: '注册',
                3: '订单',
                4: '付费',
                5: '退款'
            }
        }
    },
    mounted() {
        this.fetchAccounts()
    },
    methods: {
        handleSubmit() {
            this.$emit('handleSubmit', this.data)
            return false
        }
    },
    watch: {
        value(newVal) {
            this.data = newVal
        }
    }
}
</script>