<template>
    <div class="dialog">
        <form class="form form--dialog" method="post" @submit.prevent="handleSubmit">
            <div class="form-item">
                <label>转化类型:</label>
                <Selection :items="conversion_types" placeholder="请选择类型" v-model="conversion_type" class="colspan-2"></Selection>
            </div>
            <div class="form-action">
                <button type="submit" class="btn btn-primary">保 存</button>
                <button type="button" class="btn btn-light" @click="handleCancel">取 消</button>
            </div>
        </form>
    </div>
</template>

<script>
import Selection from './Selection.vue'

export default {
    name: 'ConversionSync',
    components: {
        Selection
    },
    data() {
        return {
            conversion_type: 0,
            conversion_types: {
                1: '激活',
                2: '注册',
                3: '订单',
                4: '付费',
                5: '退款'
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$emit('handleSubmit', this.conversion_type)
            return false
        },
        handleCancel() {
            this.$emit('handleCancel')
            return false
        }
    }
}
</script>